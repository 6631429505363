<template>
  <ExtendedView :tabs="tabs">
    <template v-slot:list="{ item }">
      <WidgetList
        v-if="item && item.key"
        :fixed-filters="fixedFilters(item.key)"
        :load-items-callback="getWidgets"
        :call-to-actions="callToActions"
        @selectWidget="selectWidget"
      />
    </template>
    <template v-slot:profile>
      <WidgetProfile
        v-if="selectedWidget"
        :widget="selectedWidget"
        :update-call-back="showEditWithPreview"
        :change-active-status-callback="updateWidget"
        :preview-url="'https://kiosk.salescloud.is/?uuid=' + selectedWidget.uuid"
        widget-type="kiosk"
      />
    </template>
  </ExtendedView>
</template>

<script>
import ExtendedView from "@/components/common/ExtendedView"
import WidgetList from "@/components/widget/WidgetList"
import WidgetProfile from "@/components/widget/WidgetProfile"

export default {
  name: "Kiosks",
  components: {
    WidgetProfile,
    WidgetList,
    ExtendedView
  },
  data() {
    return {
      widgets: [],
      selectedWidget: null
    }
  },
  computed: {
    organization() {
      return this.$store.state.organization
    },
    activeCount() {
      if(Array.isArray(this.widgets)) {
        return this.widgets.filter(widget => widget && widget.active).length
      }
      return 0
    },
    inactiveCount() {
      if(Array.isArray(this.widgets)) {
        return this.widgets.filter(widget => widget && !widget.active).length
      }
      return 0
    },
    tabs() {
      const tabs = []

      tabs.push({
        title: 'Active',
        key: 'active',
        badge: {
          content: this.activeCount
        }
      })

      tabs.push({
        title: 'Inactive',
        key: 'inactive',
        badge: {
          content: this.inactiveCount
        }
      })

      return tabs
    },
    callToActions() {
      return [
        {
          title: this.$t('add'),
          callback: this.addNewWidget
        }
      ]
    },
  },
  methods: {
    selectWidget(widget) {
      this.selectedWidget = widget
    },
    fixedFilters(key) {
      switch (key) {
        case 'active':
          return [
            {
              key: 'active',
              type: 'boolean',
              value: true,
              operator: 'equals'
            }
          ]
        case 'inactive':
          return [
            {
              key: 'active',
              type: 'boolean',
              value: true,
              operator: 'not_equals'
            }
          ]
        default:
          return []
      }
    },
    addNewWidget() {
      this.$store.commit('updateDataToMutate', {
        objectToMutate: {
          organization: this.organization.uuid,
          title: null,
          channel: null,
          locations: null,
          categories: null,
          defaultLanguage: 'en',
          showPricesWithCurrencyCode: false,
          enableSubCategories: false,
          packagingOptions: ['eatIn', 'takeAway'],
          payerInfoRequests: null,
          allowedLanguages: null,
          maintenanceMode: false,
					cardReaderPaymentMethodInstance: null,
					receiptPrinter: null,
          collapseAllVariationGroups: false
        },
        formComponent: 'components/kiosk/KioskForm',
        saveCallback: this.createWidget,
        previewUrl: 'https://kiosk.salescloud.is',
        previewStyle: 'padding-top: 177%;',
        title: this.$t('editWithPreview')
      })
    },
    createWidget(newWidget) {
      this.$store.dispatch('createKiosk', newWidget).then(result => {
        if (result) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: this.$t('createdWidgetSuccess')
          })
        } else {
          this.$store.commit('updateActionError', {
            message: this.$t('error'),
            subMessage: this.$t('couldNotCreateWidget')
          })
        }
      }).catch(() => {
        this.$store.commit('updateActionError', {
          message: this.$t('error'),
          subMessage: this.$t('couldNotCreateWidget')
        })
      })
    },
    showEditWithPreview(profile) {
      return this.$store.commit('updateDataToMutate', {
        objectToMutate: profile,
        formComponent: 'components/kiosk/KioskForm',
        saveCallback: this.updateWidget,
        previewUrl: 'https://kiosk.salescloud.is',
        previewStyle: 'padding-top: 177%;',
        title: this.$t('editWithPreview')
      })
    },
    updateWidget(newValues) {
      this.updating = true
      return this.$store.dispatch('updateKiosk', newValues).then(result => {
        if (result) {
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: this.$t('updatedWidgetSuccess')
          })
        } else {
          this.$store.commit('updateActionError', {
            message: this.$t('error'),
            subMessage: this.$t('couldNotUpdateWidget')
          })
        }
        return result
      }).catch(() => {
        this.$store.commit('updateActionError', {
          message: this.$t('error'),
          subMessage: this.$t('couldNotUpdateWidget')
        })
      }).finally(() => {
        this.editMode = false
        this.updating = false
      })
    },
    getWidgets() {
      return this.$store.dispatch('getKiosks').then(result => {
        this.widgets = result
        this.$store.commit('updateAppBarTabs', this.tabs)
        return result
      })
    }
  },
  watch: {
    widgets(value) {
      if(typeof this.selectedWidget !== 'undefined' && this.selectedWidget !== null) {
        const widgetsIndex = value.findIndex(w => w && w.uuid === this.selectedWidget.uuid)
        if(widgetsIndex >= 0) {
          this.selectedWidget = value[widgetsIndex]
        }
      }
    },
    selectedAppBarTab(current, previous) {
      if(current !== previous) {
        this.selectedWidget = null
      }
    }
  },
  created() {
    this.getWidgets()

    this.$store.commit('updateAppBarTabs', this.tabs)
    if(Array.isArray(this.tabs) && this.tabs.length > 0) {
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)
    }

    this.$store.commit('updateShowAppBar', false)
    this.$store.commit('updateShowSidebar', true)
    this.$store.commit('setMinimizeSidebar', false)
  },
  updated() {
    this.$store.commit('updateShowAppBar', false)
    this.$store.commit('updateShowSidebar', true)
    this.$store.commit('setMinimizeSidebar', false)
  },
  destroyed() {
    this.$store.commit('updateShowAppBar', false)
    this.$store.commit('updateShowSidebar', false)
    this.$store.commit('setMinimizeSidebar', true)
  }
}
</script>

<style scoped>

</style>
